@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    margin: 0;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    background-color: #fffcf9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Archivo-Regular', 'Helvetica', 'Arial', sans-serif;
  }
  a {
    text-decoration: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.sales-category-border::before {
  position: absolute;
  transform: scaleX(0);
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  border-bottom: 2px solid theme('colors.bluePrimary');
  border-radius: 2px;
}

.sales-category-border[data-state='active']::before {
  transform: scaleX(1);
}

/* Font Face Declarations */
@font-face {
  font-family: 'Archivo-Regular';
  src:
    local('Archivo-Regular'),
    url('./assets/fonts/Archivo/Archivo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Archivo-Bold';
  src:
    local('Archivo-Bold'),
    url('./assets/fonts/Archivo/Archivo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Archivo-Light';
  src:
    local('Archivo-Light'),
    url('./assets/fonts/Archivo/Archivo-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Archivo-Medium';
  src:
    local('Archivo-Medium'),
    url('./assets/fonts/Archivo/Archivo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica';
  src:
    local('Helvetica'),
    url('./assets/fonts/Helvetica/Helvetica.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica-Bold';
  src:
    local('Helvetica-Bold'),
    url('./assets/fonts/Helvetica/Helvetica-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica-Light';
  src:
    local('Helvetica-Light'),
    url('./assets/fonts/Helvetica/Helvetica-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'WorkSans-Regular';
  src:
    local('WorkSans-Regular'),
    url('./assets/fonts/WorkSans/WorkSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src:
    local('WorkSans-SemiBold'),
    url('./assets/fonts/WorkSans/WorkSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'WorkSans-Bold';
  src:
    local('WorkSans-Bold'),
    url('./assets/fonts/WorkSans/WorkSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'WorkSans-Light';
  src:
    local('WorkSans-Light'),
    url('./assets/fonts/WorkSans/WorkSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'WorkSans-Medium';
  src:
    local('WorkSans-Medium'),
    url('./assets/fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
}
